import React from "react";
import { SVG } from '../../types/svg'

export default ({ ...props }: SVG) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 45 45"
			fill="none"
			{...props}
		>
			<rect width="45" height="45" fill="#84A919" rx="22.5"></rect>
			<path
				fill="#fff"
				fillRule="evenodd"
				d="M17 13v18a1 1 0 001.54.841l14-9a1 1 0 000-1.682l-14-9A1 1 0 0017 13zm13.15 9L19 29.168V14.832L30.15 22z"
				clipRule="evenodd"
			></path>
		</svg>
	);
}