import React, { useEffect, useState } from "react"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

import { ProgramProps } from "../interfaces"
import { mediaMax } from "../../../utils/breakpoints"
import { DateButton, Timeline, DetailItem } from './index';
import ControlSelectDate from '../../Framundan/components/ControlSelectDate'
import CardList from '../../Framundan/components/CardList'
import Slider from '../../Slider';

const Program = ({
  isActive = "notActive",
  liveSpeaker,
  speakerLinks,
  backgroundImage,
  data,
  title,
  liveUrl,
  hideTimeline,
  smallerLogo,
}: ProgramProps) => {

  const [activedate, setActiveDate] = useState(0)
  const [speakersByDate, setSpeakersByDate] = useState([]);

  const liveSpeakerSlug = liveSpeaker ? liveSpeaker.slug : null
  const withLinkList = speakerLinks != null ? speakerLinks : []

  const handleDayClick = (index: number) => {
    setActiveDate(index)
  }

  return (
    <StyledContainer
      fluid={backgroundImage.childImageSharp.fluid}
      className={isActive}
      id="myScroller"
    >
      <StyledTop>
        <Title>{title}</Title>
      </StyledTop>

     
      {!hideTimeline && (
        <Timeline speakerArray={data} liveUrl={liveUrl}></Timeline>
      )}
      <StyledSlider>
        <CardList events={data} liveSlug={liveSpeakerSlug} withLinkList={withLinkList}/>
      </StyledSlider>

      
    </StyledContainer>
  )
}

const Title = styled.h1`
	flex: 1;
	font-family: ${({theme}) => theme.fonts.fontFamilyPrimary};
	color: ${({theme}) => theme.colors.white};
	font-style: normal;
	font-weight: 300;
	letter-spacing: 0em;
	text-align: left;

	font-size: max(30px, 2.92vw);
	line-height: 127%;

	@media ${mediaMax.tabletL} {
		flex: none;
		width: 100%;
		margin-bottom: 32px;
		padding: 0 20px;
	}
`

const StyledSlider = styled(Slider)`
	width: 100%;
	display: flex;
	padding-top: 1.38889vw;
`

const StyledTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 81vw;
  margin-left: 9.5vw;
  margin-bottom: 70px;

  @media ${mediaMax.tabletL} {
    display: flex;
    flex-direction: column;
  }

  @media ${mediaMax.mobileL} {
    margin-bottom: 35px;
  }
`

const StyledDayChoices = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledContainer = styled(BackgroundImage)`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  transition: transform 0.7s cubic-bezier(0.4, 0, 0.2, 1);
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  background-position: bottom right;
`

export default Program;