//Get calculations needed for event placement
import { CalculationObject } from '../interfaces';
import { Event } from '../../../types/events';

export const getCalculations = (firstEvent : Event, lastEvent : Event) => {
    let calcObject : CalculationObject = {
      "startMinute": parseInt(firstEvent.time.substring(3,6)),
      "startHour": parseInt(firstEvent.time.substring(0,3)),
      "endMinute": parseInt(lastEvent.timeEnd.substring(3,6)),
      "endHour": parseInt(lastEvent.timeEnd.substring(0,3))
    }
    return calcObject
}

export const isNewHour = (num : number, modulusBase : number) => { 
  
  return num % modulusBase;
}

export const getTimes = (calculations : CalculationObject, hourCounter : number, i : number) => {
  let quartedHour = "0";
  let hour = "0";

  hour = (calculations.startHour + hourCounter).toString().length == 1 ? "0" + (calculations.startHour + hourCounter).toString() : (calculations.startHour + hourCounter).toString();
  quartedHour = ((((calculations.startMinute/15) + i) % 4) * 15).toString().length == 1 ? "0" + ((((calculations.startMinute/15) + i) % 4) * 15).toString() : ((((calculations.startMinute/15) + i) % 4) * 15).toString();

  return hour + ":" + quartedHour
}

export const getMinutes = (calculations : CalculationObject) => {
  let allMinutes = [];
  let totalMinutesBetweenTimes = 0;
  let hourCounter = 0;

  if(calculations.endMinute > calculations.startMinute){
    const minutes = calculations.endMinute - calculations.startMinute;
    const hours = calculations.endHour - calculations.startHour;
    totalMinutesBetweenTimes = hours * 60 + minutes;
  }
  else{
    const minutes = (calculations.endMinute + 60) - calculations.startMinute;
    const hours = (calculations.endHour - 1) - calculations.startHour;
    totalMinutesBetweenTimes = hours * 60 + minutes;
  }

  for(var i = 0; i < totalMinutesBetweenTimes + 1; i++){
    const tmpHour = (calculations.startHour + hourCounter).toString().length === 1 ? "0" + (calculations.startHour + hourCounter) : (calculations.startHour + hourCounter);
    const tmpMinute = ((calculations.startMinute + i ) % 60 ).toString().length === 1 ? "0" + ((calculations.startMinute + i ) % 60 ) : ((calculations.startMinute + i ) % 60 );
    let time = tmpHour + ":" + tmpMinute;
    
    if (
      isNewHour(parseInt(time.substring(3, 6)), 59) == 0 &&
      parseInt(time.substring(3, 6)) !== 0
    ) {
      hourCounter++
    }
    allMinutes.push(time);
  }

  return allMinutes;

}