import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import DynamicContext from '../../../context';
import { getCalculations, getMinutes } from '../utils';
import { IntervalEvent, IntervalItem } from './index';
import { TimelineProps, CalculationObject } from '../interfaces';
import { mediaMax } from '../../../utils/breakpoints';

const Timeline = ({ speakerArray, liveUrl, activeDate } : TimelineProps) => {

  const [nrOfSlots, setNrOfSlots] = useState(0);
  const [timeline, setTimeline] = useState([]);
  const [timelineEventItems, setTimelineEventItems] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);

  //get live event and updates for recording links
  const { liveSpeaker, speakerLinks } = useContext(DynamicContext)
  const liveSpeakerSlug = liveSpeaker ? liveSpeaker.slug : null
  const withLinkList = speakerLinks != null ? speakerLinks : []

  const PopulateData = () => {
    const timeLineItems = []
    const timeLineEventItems = []

    const totalTimeCalculations = getCalculations(speakerArray[0], speakerArray[speakerArray.length - 1]);

    const allMinutes = getMinutes(totalTimeCalculations);

    //CREATE BASE FOR TIMELINE
    for (let i = 0; i < allMinutes.length; i++) {
      timeLineItems.push(
       <IntervalItem columnStart={i+1} time={allMinutes[i]} key={"intervalitem - " + `${i+1}`}></IntervalItem>
      )
    }

    setNrOfSlots(allMinutes.length-1);

    //CREATE ARRAY OF EVENTS TO PLACE ON ARRAY
    for (let i = 0; i < speakerArray.length; i++) {
      //get the start time of each speaker
      let start = speakerArray[i].time

      //find where in the grid the event should start
      let index = allMinutes.indexOf(start) + 1
      const timeObject : CalculationObject = {
        "startHour" : parseInt(speakerArray[i].time.substring(0,3)),
        "startMinute" : parseInt(speakerArray[i].time.substring(3,6)),
        "endHour" : parseInt(speakerArray[i].timeEnd.substring(0,3)),
        "endMinute" : parseInt(speakerArray[i].timeEnd.substring(3,6))
      }

      let span = getMinutes(timeObject)

      const isLive = liveSpeakerSlug == speakerArray[i].slug
      const VideoLink = withLinkList.filter(
        (item: any) =>
          item.slug == speakerArray[i].slug
      )
      let itemHref : string;
      if (isLive) {
        itemHref = liveUrl
      } else if (VideoLink.length > 0) {
        itemHref = "/upptokur#" + speakerArray[i].slug
      } else {
        itemHref =
          "/fyrirlesari/" + speakerArray[i].slug
      }

      //Create Event items on the timeline
      timeLineEventItems.push(
        <IntervalEvent 
          columnStart={index} 
          columnSpan={span.length - 1} 
          eventLink={itemHref} 
          image={speakerArray[i].image}
          key={"intervalitem - " + `${i+1}`}
        ></IntervalEvent>
      )
    }

    setTimeline(timeLineItems)
    setTimelineEventItems(timeLineEventItems)
    setDataLoading(false)
  }

  useEffect(() => {
    PopulateData()
  }, [activeDate])
  
  return(
    <StyledTimeline nrOfTimes={nrOfSlots}>
      {timeline}
      {timelineEventItems}
    </StyledTimeline>
  )
}

export default Timeline;

interface StyledTimelineProps{
  nrOfTimes? : number
}

const StyledTimeline = styled.div<StyledTimelineProps>`
  width: 81vw;
  margin-left: 9.5vw;
  height: 55px;
  display: grid;
  margin-bottom: 70px;
  grid-template-rows: 55px;
  max-width:90.5vw;
  grid-template-columns: repeat(${({ nrOfTimes }) => nrOfTimes}, 1fr);
  background-color: #E38AD2;

  ::-webkit-scrollbar {
    display: none;
  }
  @media ${mediaMax.laptopL} {
    overflow-x: scroll;
    height: 90px;
    padding-top: 30px;
    width: 100%;
    margin-bottom: 30px;
  }
  @media ${mediaMax.tabletL}{
    display:none;
  }
`