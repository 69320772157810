import React from 'react'
import styled from 'styled-components'
import Img, { FixedObject } from "gatsby-image"

interface Image {
	image: {
		childImageSharp: {
			fixed: FixedObject
		}
	},
	alt?: string
}

interface StackedImagesProps {
	expand?: boolean
	className?: string
	images: Array<Image>
}
export default ({
	expand,
	className,
	images
}: StackedImagesProps) => {
	const imagesSliced = images.slice(0,3)
	return (
		<Container className={`${className} ${expand && 'expand'}`}>
			{imagesSliced.map((image, index) => (
				<Circle index={index} key={index}>
					<StyledImage fixed={image.image.childImageSharp.fixed} alt={image.alt} title={image.alt} />
				</Circle>
			))}
			{images.length > 3 && (
				<Circle index={3}>
					<CircleText>{`+${images.length - 3}`}</CircleText>
				</Circle>
			)}
		</Container>
	)
}

const spacing = 0.34722;
interface CircleProps { index: number }
export const Circle = styled.div<CircleProps>`
	position: relative;
	z-index: ${({ index }) => index * -10};
	border-radius: 50%;
	background: #f3f3f3;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
	transition: transform 0.45s cubic-bezier(0.65, 0, 0.076, 1);
	overflow: hidden;

	width: 3.125vw;
	height: 3.125vw;
	margin-right: ${spacing}vw;
	display: flex;
	align-items: center;
	justify-content: center;

	font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
	color: ${({ theme }) => theme.colors.black};
	font-style: normal;
	font-weight: bold;
	font-size: 1.6em; // TODO
	line-height: 137.5%;

	&:last-child {
		margin-right: 0;
	}

	${({ index }) => {
		if (index == 0) { return ``; }

		let newIndex = index === 3 ? index - 1 : index
		const moveSelf = -50 * newIndex
		const moveSpacing = spacing * newIndex
		return `transform: translateX(calc(${moveSelf}% - ${moveSpacing}vw));`
	}}
`

const Container = styled.div`
	position: relative;
	z-index: 10;
	display: flex;

	&.expand ${Circle} {
		@media (hover: hover) {
			transform: translateX(0);
		}
	}
`

const StyledImage = styled(Img)`
	position: absolute !important;
	height: 100% !important;
	width: 100% !important;
`

const CircleText = styled.div`
	
`