import React from 'react'
import styled from 'styled-components'

import { Event } from '../../../types/events'
import Image from 'gatsby-image';
import { mediaMax } from '../../../utils/breakpoints'
import SVGPlay from '../../Svg/Play'
import { Link } from 'gatsby';

import Card, {
	Spacer,
	ContentTop,
	ContentTopText,
	ContentMain,
	ContentBottom
} from '../../Card'
import StackedImages, { Circle } from '../../StackedImages'
//import SVGStar from '../../Svg/Star'
import ButtonCircle from '../../Buttons/Circle'
import SVGArrowRight from '../../Svg/ArrowRight';

interface CardListProps {
	events: Array<Event> | undefined
	liveSlug : string
	withLinkList : any
}

export default ({
	events,
	liveSlug,
	withLinkList
}: CardListProps) => {
	if (!events || events.length === 0) {
		return <div>No content</div>
	}

	const cards = events.map((event, index) => {
		const iHaveALink = withLinkList.filter(({ slug } : any) => slug === event.slug).length > 0;
		return(
			<StyledCard
        key={index}
				transitionDelay={index * 80}
				to={"/fyrirlesari/" + event.slug}
				className={liveSlug == event.slug ? "live" : ""}
      >
        <Spacer>
          <ContentTop>
            <ContentTopText>{event.time}</ContentTopText>
						{iHaveALink && <Link to={`/upptokur#${event.slug}`}><StyledTopButtonCircle icon={<SVGPlay width="100%" />} /></Link> }
          </ContentTop>

          <ContentMain>
            {event.title}
          </ContentMain>

          <StyledContentBottom>
            {event.image !== undefined && event.image !== null && <CircleImage fluid={event.image.childImageSharp.fluid}/>}
            <InfoWrapper>
              <Speaker>{event.speakerName}</Speaker>
              <Company>{event.companyName}</Company>
            </InfoWrapper>
          </StyledContentBottom>
					<StyledButtonCircle icon={<SVGArrowRight />} />
        </Spacer>
      </StyledCard>
		)
	})

	return (
		<>
			<LeftSpace />
			{cards}
			<RightSpace />
		</>
	)
}

const StyledContentBottom = styled(ContentBottom)`
	max-width:80%;
	align-items:center;
	justify-content:flex-start;
`

const CircleImage = styled(Image)`
  min-height: 4.5em;
  min-width: 4.5em;
  border-radius: 50%;
  width: 4.5em;
  height: 4.5em;
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  font-family: ${({theme}) => theme.fonts.fontFamilyPrimary};
`

const Company = styled.span`
  font-size: 11px;
  line-height: 15px;
  color:#000;
  font-family: ${({theme}) => theme.fonts.fontFamilyPrimary};
`

const Speaker = styled.span`
  font-size:14px;
  line-height:18px;
  color:#000;
  font-family: ${({theme}) => theme.fonts.fontFamilyPrimary};
`

const LeftSpace = styled.div`
	min-width: 9vw;

	@media ${mediaMax.tabletL} {
		min-width: 20px;
	}
`

const RightSpace = styled.div`
	min-width: 9vw;

	@media ${mediaMax.tabletL} {
		min-width: 20px;
	}
`

const StyledCard = styled(Card)`
	margin-right: 1.6667%;

	&.live{
		border:2px solid red;
	}

	@media ${mediaMax.tabletL} {
		font-size: 10px;
		height: 220px;
		width: 335px;
		min-width: 335px;
		margin-right: 20px;

		${Spacer} {
			padding: 25px 20px 20px 20px;
		}

		${ContentMain} {
			font-size: 18px;
		}
		/* display: none; */
	}

	&.last {
		margin-right: 0;
	}
`

const StyledStackedImages = styled(StackedImages)`

	@media ${mediaMax.tabletL} {
		${Circle} {
			width: 45px;
			height: 45px;
		}
	}
` 

const StyledButtonCircle = styled(ButtonCircle)`
	font-size: 100%;
	width: 4.5em;
	height: 4.5em;
	position:absolute;
	right:20px;
	bottom:40px;

	> svg{
		width:53%;
	}
`

const StyledTopButtonCircle = styled(ButtonCircle)`
	font-size: 100%;
	width: 3.5em;
	height: 3.5em;
	position:absolute;
	right:20px;
	top:30px;
`

// const StyledButtonCircleStar = styled(ButtonCircle)`
// 	background: transparent;
// 	font-size: 100%;
// 	width: 2.4em;
// 	height: 2.4em;

// 	& > svg {
// 		width: 100%;
// 	}
// `