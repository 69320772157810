import React from 'react';
import styled from 'styled-components';
import { DateButtonProps } from '../interfaces';

const DateButton = ({ isActive, day, start, end, handleDayClick } : DateButtonProps) => {  
  return(
    <StyledButton
      className={isActive ? "active" : ""}
      onClick={handleDayClick}
    >
      <span className="day">{day}</span>
      <div className="rightContent">
        <span className="date">SEP 2020</span>
        <span className="time">
          {start} - {end}
        </span>
      </div>
    </StyledButton>
  )
}

export default DateButton;

const StyledButton = styled.div`
  height: 72px;
    width: 170px;
    background-color: transparent;
    border: 1px solid #e5e5e5;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-left: 25px;
    border-radius: 3px;
    outline: none;
    align-items: center;

    .day {
      font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
      font-size: 48px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 72px;
      color: #fff;
      margin-top: 3px;
    }

    .rightContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 25px;

      .date {
        font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 24px;
        color: #fff;
      }

      .time {
        font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
        color: #fff;
      }
    }

    &.active {
      background-color: white;
      color: #202020;

      .day,
      .date,
      .time {
        color: #202020;
      }
    }
`