  import React from 'react';
import styled from 'styled-components';
import { livePulse, animation_image } from '../../ConferenceRoom/utils/animations';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import InformationSVG from '../../Svg/Information';

interface DetailItemProps{
  index?: number,
  Url : string,
  time? : string,
  title : string,
  speakerName? : string,
  companyName? : string,
  image?: any,
  speakerKey? : string,
  liveSpeakerSlug?: string,
  linkList?: any,
  onFoundLive?: () => void,
  smallerImage?: boolean
}

const DetailItem = ({ linkList, index, Url, time, title, speakerName, companyName, image, speakerKey, liveSpeakerSlug, onFoundLive, smallerImage} : DetailItemProps) => {
  const VideoLink = linkList !== undefined ? linkList.filter((item : any) => item.slug == speakerKey) : "";
  const isLive = liveSpeakerSlug !== undefined ? liveSpeakerSlug == speakerKey : false;
  let cardHref = "";

  const defaultImage = useStaticQuery(
    graphql`
      query {
        file(name: { eq: "user advania-01-01" }) {
          childImageSharp {
            fluid {
              base64
              aspectRatio
              src
              srcSet
              sizes
              originalImg
            }
          }
        }
      }
    `
  )

  if(VideoLink.length > 0){
    cardHref = "/upptokur#" + speakerKey;
  }else if(isLive){
    cardHref = "/beint";
  }
  else{
    cardHref = Url;
  }
  return(
    <CardWrapper>
      <StyledDetailEventItem key={speakerKey} className={index == 0 ? "firstItem EventItem" : "EventItem"} id={isLive ? "liveEvent" : ""}>
        <div className="card-content-top">
          <div className="card-date">{time}</div>
          {(isLive || VideoLink.length > 0) && 
            <StyledLiveIndicator href={cardHref} className={isLive ? "live" : "play"}></StyledLiveIndicator>
          }
        </div>
        <InfoLink href={Url}>
            <InformationSVG fill="none" stroke="#303030" width="34px" className="icon" />
        </InfoLink>

        <TitleLink href={cardHref}>{title}</TitleLink>
        <div className="speaker">
          <SpeakerInfo href={cardHref}>{speakerName}</SpeakerInfo>
          <SpeakerInfo href={cardHref}>{companyName}</SpeakerInfo>
        </div>
        {
          ( image !== null && image !== undefined )
          ? <a href={cardHref}><DetailImageWrapper className={smallerImage ? "smaller" : ""}><Img fluid={image.childImageSharp.fluid} alt={"mynd af" + `${speakerName}`} /></DetailImageWrapper></a>
          : <a href={cardHref}><DetailImageWrapper className={smallerImage? "hidden" : ""}><Img className="image" fluid={defaultImage.file.childImageSharp.fluid}></Img></DetailImageWrapper></a>
        }
      </StyledDetailEventItem>
    </CardWrapper>
  )
  
}

export default DetailItem;

const SpeakerInfo = styled.a`
  font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
  color: #202020;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-decoration:none;
`

const CardWrapper = styled.div` 
  padding-right:10px;
  width:100%;

  @media(max-width:768px){
    margin-right:15px;
  }
`

const StyledLiveIndicator = styled.a`
  width: 35px;
  height: 35px;
  position:relative;
  margin-right:30px;

  &.play {
    border-radius: 50%;
    background:linear-gradient(180deg, #D8EA78 0%, #769A30 100%);

    &:after{
      content:"";
      position:absolute;
      top: 9px;
      right: 12px;
      width: 0; 
      height: 0; 
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 8px solid white;
      border-radius:3px;
    }
  }

  &.live {
	  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    width:40px;
    height:40px;

    &:before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      background: rgba(255, 82, 82, 1);
      border-radius: 50%;
      animation: ${livePulse} 2s infinite;
    }
  }

  &:hover.live:before {
    box-shadow: 0 0 0 5px rgba(255, 82, 82, 0.5);
    animation: none;
  }
`

const DetailImageWrapper = styled.div`
  position:absolute;
  right:0;
  bottom:0;
  height: 130px;
  width: 130px;

  &.smaller{
    right: 10px;
    bottom: 10px;
    height: 78px;
    width: 97px;
  }

  &.hidden{
    display:none;
  }
`

const TitleLink = styled.a`
  font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
  color: #303030;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  margin-right: 75px;
  grid-row: 2 / span 1;
  text-decoration:none;
`

const StyledDetailEventItem = styled.div`
  height:240px;
  width:100%;
  min-width:375px;
  width:375px;
  margin-right:25px;
  background-color:White;
  position:Relative;
  padding:30px 0 25px 25px;
  display:grid;
  grid-template-rows: 30px 108px 47px;
  grid-template-columns: 1fr;
  overflow:hidden;
  border-radius: 3px;
  text-decoration:none;
  position:relative;

  &#liveEvent{
    border: 3px solid rgba(255, 82, 82, 1);;
  }
  /* transform:translate3d(0,0px,0);
  transition:transform 0.3s linear;

  &:hover{
    transition:transform 0.3s linear;
    transform:translate3d(0,-10px,0);
  } */

  

  &.firstItem{
    margin-left:9.5vw;

    @media(max-width:768px){
      margin-left:0;
    }
  }

  .date{
    color: #202020;
    font-size: 14px;
    font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
    letter-spacing: 0;
    line-height: 20px;
    grid-row: 1 / span 1;
  }

  .card-content-top {
    grid-row: 1 / span 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 24px;

    .card-date {
      color: #202020;
      font-size: 14px;
      font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
      letter-spacing: 0;
      line-height: 20px;
    }
  }

  .speaker{
    display:flex;
    flex-direction:column;
    grid-row: 3 / span 1;
    span{
      font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
      color: #202020;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
    }
  }

  img{
    position:absolute;
    right:0;
    bottom:0;

  transform:translate3d(0,  0px,0);
       transition:transform 0.3s linear;
  }

`

const InfoLink = styled.a`
  text-decoration: none;
  height:35px;
  width:35px;
  position:absolute;
  right: 15px;
  top: 27px;

  &:visited, &:focus {
    outline: none;
    color: #303030;
  }
`