import React, { ReactNode } from 'react'
import styled from 'styled-components'

interface SliderProps {
	className?: string,
	children?: ReactNode
}

export default ({
	className,
	children
}: SliderProps) => {

	return (
		<Container className={className}>
			{children}
		</Container>
	)
}


const Container = styled.div`
	overflow-x: auto;
	padding-bottom: 25px;

	&::-webkit-scrollbar {
		width: 2px;
		height: 8px;
	}
	&::-webkit-scrollbar-button {
		width: 0px;
		height: 0px;
	}
	&::-webkit-scrollbar-thumb {
		background: #e1e1e1;
		border: 0px none #ffffff;
		border-radius: 50px;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: #ffffff;
	}
	&::-webkit-scrollbar-thumb:active {
		background: #e7e7e7;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
		border: 0px none #ffffff;
		border-radius: 50px;
	}
	&::-webkit-scrollbar-track:hover {
		background: transparent;
	}
	&::-webkit-scrollbar-track:active {
		background: transparent;
	}
	&::-webkit-scrollbar-corner {
		background: transparent;
	}
`