import React from 'react';
import styled from 'styled-components';
import { mediaMax } from '../../../utils/breakpoints';

interface IntervalProps{
  columnStart? : number
  time? : string
}

const IntervalItem = ({ columnStart, time} : IntervalProps) => {
  return(
    <StyledIntervalItem
      columnStart={columnStart}
      style={{gridColumn: `${columnStart} / span 1`}}
    >
      <span className="time">{time}</span>
    </StyledIntervalItem>
  )
}

export default IntervalItem;

const StyledIntervalItem = styled.div<IntervalProps>`
  height: 55px;
  width: 100%;
  border-left: 1px solid rgba(255, 255, 255, 0.15);
  position: relative;
  
  grid-row: 1 / span 1;
  font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};

  &:nth-child(15n + 1){
    border-left: 1px solid #fff;

    &:before {
      content: "";
      position: absolute;
      top: -5px;
      left: -3px;
      height: 5px;
      width: 5px;
      border-radius: 50%;
      background-color: #fff;
    }

    .time{
      display:block;
    }

    span {
      color: white;
      position: absolute;
      top: -30px;
      left: -15px;
      font-size: 12px;
      letter-spacing: 0;
    }
  }

  &:nth-child(1){
    .time {
      left: 0;
    }
  }

  .time, span {
    display: none;
  }

  &:before {
    content: none;
  }

  &::last-child() {
    width: 1px;
  }

  

  

  @media ${mediaMax.laptopL} {
    .time {
      position: relative;
      margin-right: 25px;
    }
  }
`