import React, { Suspense, useEffect, useState } from "react"
import styled from "styled-components"

import { mediaMax, mediaMin } from "../../../utils/breakpoints"
import { DetailItem } from './index';
import Image from 'gatsby-image';

import Card, { 
	Spacer, 
	ContentTop, 
	ContentTopText, 
	ContentMain, 
	ContentBottom 
} from '../../Card'

interface Speaker{
    slug: string,
    title: string,
    speakerName: string,
    companyName: string,
    image: any,
    time: string,
    timeEnd: string
}
interface Node{
  node: Speaker
}
interface SimpleProgramProps{
  isActive?: string,
  data: Array<Node>,
  backgroundImage?: any,
  title?: string,
  liveUrl?: string,
  hideTimeline?: boolean,
  smallerLogo? : boolean
}

const SimpleProgram = ({
  data
}: SimpleProgramProps) => {
  
  const cards = data.map((event, index) => {
    return(
      <StyledCard
        key={index}
        transitionDelay={index * 80}
      >
        <Spacer>
          <ContentTop>
            {/* <ContentTopText>{event.node.time} - {event.node.timeEnd}</ContentTopText> */}
          </ContentTop>

          <ContentMain>
            {event.node.title}
          </ContentMain>

          <ContentBottom>
            {event.node.image !== undefined && event.node.image !== null && <CircleImage fluid={event.node.image.childImageSharp.fluid}/>}
            <InfoWrapper>
              <Speaker>{event.node.speakerName}</Speaker>
              <Company>{event.node.companyName}</Company>
            </InfoWrapper>
          </ContentBottom>
        </Spacer>
      
      </StyledCard>
      )
  
  })

return (
    <Content>
      {cards}
    </Content>
  )
}

const CircleImage = styled(Image)`
  min-height: 45px;
  min-width: 45px;
  border-radius: 50%;
  width: 45px;
  height: 45px;
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  font-family: ${({theme}) => theme.fonts.fontFamilyPrimary};
`

const Company = styled.span`
  font-size: 11px;
  line-height: 15px;
  color:#000;
  font-family: ${({theme}) => theme.fonts.fontFamilyPrimary};
`

const Speaker = styled.span`
  font-size:14px;
  line-height:18px;
  color:#000;
  font-family: ${({theme}) => theme.fonts.fontFamilyPrimary};
`

const Content = styled.div`
  max-width: 50vw;
  flex-wrap:wrap;
  width: 50vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  padding-bottom:100px;
  z-index: 30;

	@media ${mediaMax.tabletL} {
		//margin-top:100px;
	}

  @media ${mediaMax.tablet} {
    width:100%;
    max-width:100%;
    height: auto;
  }

`

const StyledCard = styled(Card)`
	margin-right: 1.6667%;
  margin-bottom:15px;

  @media(max-width:1200px){
    width:100%;
    min-width:100%;
  }

  @media ${mediaMax.tablet}{
    width:48%;
    min-width:48%;
  }

  @media ${mediaMax.mobileL}{
    width:100%;
    min-width:100%;
  }
`

export default SimpleProgram;